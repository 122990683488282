import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img} from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const InfoSection = ({ 
    
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    description2,
    description3,
    description4,
    description5,
    buttonLabel,
    img,
    alt,
    imgStart,
    start
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                            <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{description4}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{description5}</Subtitle>
                            <Link to='/'>
                            {buttonLabel ? (
                                <Button big fontBig primary={primary}>
                                    {buttonLabel}
                                </Button>
                                ) : null}
                            </Link>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt} />
                        </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection;