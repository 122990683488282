import React from 'react';
import {InfoSec,Heading, Subtitle , BoldText , List , LineText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const PrivacyPolicy = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
            <Heading>Privacy Policy</Heading>
                <Subtitle>This Privacy Policy explains how information is collected, used and disclosed by Gooddaybet with respect to your access and use of our services through our mobile applications and website. This Privacy Policy doesn’t apply to any third-party websites, services or applications that can be accessed through our services.</Subtitle>
                <Subtitle>Any information that is collected is subject to the Privacy Policy in effect at the time such information is collected. We may, however, modify and revise our Privacy Policy from time-to-time. If we make any material changes to this policy, we will notify you of such changes by posting them on our website, through our services or by sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use our services after those changes become effective, you are agreeing to be bound by the revised policy.</Subtitle>
                <Subtitle><strong>Note regarding children.</strong> We do not knowingly collect personal information from children. If we learn that we have collected personal information of a child under 13, we will take steps to delete such information from our files as soon as possible.</Subtitle>
                <Subtitle><strong>Note regarding international users.</strong>Your information will be transferred to and maintained on computers located in the United States (or any other country where we operate) and processed there. By providing us with your information, you are accepting and agreeing to that transfer.</Subtitle>

                <Heading>Information We Collect</Heading>
                <Subtitle>Your Device/Computer. We collect certain information that your mobile device sends when you use our services, like a device identifier, user settings, and the operating system, as well as information about your use of our services through your device. We also collect certain information that your web browser sends when you visit our website, like the IP address, browser, referring/exit pages and URLs, pages viewed, and other such information about your use of our services.</Subtitle>

                <Heading>Information Collected by Others</Heading>
                <Subtitle>We may use analytics providers to help us understand the use of our services. These providers will collect the information sent by your mobile device or browser, and their use of that information is governed by their applicable privacy policy.</Subtitle>
                
                <Heading>How We Use the Information We Collect</Heading>
                <Subtitle>We use the information we collect to provide our services, to respond to inquiries, to personalize and improve our services and your experiences when you use our services, to monitor and analyze usage and trends of our services, to send you administrative messages regarding the operation and use of our services, and for any other purpose for which the information was collected.</Subtitle>
                <Heading>Information We Share With Others</Heading>
                <Subtitle>We will share information about you when you instruct us to do so, or if we notify you that the information you provide will be shared in a particular manner and you provide such information.</Subtitle>
                <Subtitle>We may share information about you in anonymous and/or aggregated form with third parties for usage analytics (to help us better understand who is using our services and how), and for industry analysis, demographic profiling, research, and other similar purposes.</Subtitle>
                <Subtitle>Your information may be accessed and used by our service providers who are working with us in connection with the operation of our services (these service providers may have access to your information but only to the extent necessary to perform services on our behalf and are obligated not to disclose that information or use it for any other purposes).</Subtitle>
                <Subtitle>We may transfer and/or provide information about our users in connection with an acquisition, sale of company assets, or other situation where user information would be transferred as one of our business assets.</Subtitle>

                <Heading>Account deletion</Heading>
                <BoldText>Important : </BoldText>
                <Subtitle>- Uninstalling or deleting the app will not delete your account.</Subtitle>
                <Subtitle>- When you delete your account, all related data will also be deleted.</Subtitle>
                <Subtitle>- If you registered your email address to our apps, you will no longer be able to use it to log in.</Subtitle>
                <Subtitle>- Even if you reinstall the apps, you will not be able to transfer your account, as data cannot be restored from a deleted account.</Subtitle>
                <BoldText>To delete your account:</BoldText>
                <Subtitle>1. Login >Tap the profile tab >Delete Account> Confirm</Subtitle>


                <Heading>Contacting Us</Heading>
                <Subtitle>If you have any questions about our Privacy Policy, please contact us at emily.grey2301@gmail.com</Subtitle>

            </Container>
        </InfoSec>
    );
  }
  
  export default PrivacyPolicy